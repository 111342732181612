import { createTheme, PaletteMode } from "@mui/material";


  export const Colors = {
    primary: {
        light: "#2D6997",
        dark: "#1F4969"
    },
    secondary: "#04e8d9",
    success: "#4CAF50",
    info: "#00a2ff",
    danger: "#FF5722",
    warning: "#FFC107",
    dark: "#0e1b20",
    light: "#aaa",
    muted: "#abafb3",
    border: "#DDDFE1",
    inverse: "#2F3D4A",
    shaft: "#333",
    dove_gray: "#d5d5d5",
    body_bg: "#f3f6f9",
    background: {
      default: {
        light: "#FAFAFA",
        dark: "#03152B"
      },
      paper: {
        light: "#FFFFFF",
        dark: "#1F344E"
      },
      paperTransparent: {
        light: "rgba(255,255,255,0.5)",
        dark: "rgba(0,0,0,0.5)"
      },
    },
    divider: {
      light: "rgba(0,0,0,0.12)",
      dark: "rgba(255,255,255,0.12)",
      lightWhite: "rgba(255,255,255,0.12)"
    },
    ///////////////
    // Solid Color
    ///////////////
    white: "#fff",
    black: "#000",
    footerBackground: '#1d2127',
    footerLightGrey: '#262b30',
    footerPaper: 'rgb(60, 66, 68,0.1)',
    footerBoxChild: 'rgb(60, 66, 68,0.3)',
    footerItemtitle: 'rgba(255,255,255,0.8)',
  };
  declare module '@mui/material/styles' {
    interface BreakpointOverrides {
      cb800: true; // adds the `cb800` breakpoint
      sb1400: true
    }
  }

  const getColorPaletteDependingOnMode = (mode: PaletteMode) => ({
    palette: {
    mode,
      ...(mode === 'light'
        ? {
            // palette values for light mode
            primary: { 
                main: Colors.primary.light 
            },
            secondary: {
              main: Colors.secondary
            },
            background: {
              default: 'whitesmoke',
            },
            divider: Colors.divider.lightWhite,
            SvgIcon: Colors.primary.light
          }
        : {
            // palette values for dark mode
            primary: { 
                main: Colors.primary.dark 
            },
            secondary: {
              main: Colors.secondary
            },
            background: {
              default: Colors.background.default.dark,
              paper: Colors.background.paper.dark,
            },
            SvgIcon: Colors.primary.light
          }),
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        cb800: 800,
        md: 900,
        lg: 1200,
        sb1400: 1400,
        xl: 1536,
      },
    },
  });

  export default getColorPaletteDependingOnMode;
  