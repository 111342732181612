import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import ToggleColorMode from '../../src/styles/ToggleColorMode';
import Favicon from '../../src/favicon.ico';

export default function TopLayout(props: any) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'de',
          'xmlns:og': 'http://ogp.me/ns#',
          'xmlns:fb': 'http://ogp.me/ns/fb#',
        }}
      >
        <title>Digital Native Solutions GmbH</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="apple-touch-fullscreen" content="yes"></meta>
        <meta name="msapplication-TileImage" content={Favicon}></meta>
        <link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
      </Helmet>
      <ToggleColorMode>{props.children}</ToggleColorMode>
    </>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
